import Vue from "vue";
import Vuex from "vuex";
import user from "./user";
import appData from "./appData";
import settings from "./settings";
import library from "./library";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		user,
		settings,
		appData,
		library,
	},
	state: {
		loading: false,
		instances: [],
		instance: null,
		hives: [],
		cats: [],
		theApp: {},
		globalSearch: '',
		sidebarSelection: "Worksheets",
		worksheetModal: {
			visible: false,
		},
		fileStructure: []
	},
	mutations: {
		SET_FILE_STRUCTURE(state, data) {
			state.fileStructure = data;
		},
		ADD_WORKSHEET(state) {
			state.worksheetModal = {
				visible: true,
			};
		},
		CLOSE_ADD_WORKSHEET(state) {
			state.worksheetModal = {
				visible: false,
			};
		},
		SET_APP(state, data) {
			state.theApp = data;
		},
		SET_INSTANCE: (state, data) => {
			state.instance = data;
			state.cats = data.activeApps;
		},
		SIDEBAR_SELECTION(state, data) {
			state.sidebarSelection = data;
		},
		UPDATE_GLOBAL_SEARCH(state, data = '') {
			state.globalSearch = data;
		},
		LOAD: (state, bool = !state.loading) => state.loading = bool,
	},
	getters: {},
	actions: {},
});
