<template>
	<div id="app">
		<BhLoading :show="loading" :loadingText="true" />
		<MediaSelector />
		<PagePreview />
		<localization></localization>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Localization from 'bh-mod/localization'
import MediaSelector from 'bh-mod/components/file/MediaSelector'
import PagePreview from '@/components/common/PagePreview'
import BhLoading from 'bh-mod/components/common/Loading'

export default {
	name: 'app',
	components: { Localization, MediaSelector, BhLoading, PagePreview },
	computed: {
		...mapGetters(['user']),
		loading() {
			return this.$store.state.loading
		}
	},
	watch: {
		'$route'(to, from) {
			if (document.querySelector('.scrolling-shadow')) {
				document.querySelector('.scrolling-shadow').classList.remove('scrolling-shadow')
			}
			const query = Object.assign({}, to.query)
			this.$store.commit('SETUP_URL_SETTINGS', query)
			if (to.path === '/settings') {
				this.$store.commit("SIDEBAR_SELECTION", 'Settings');
			}
		},
	},
	methods: {

	}
}
</script>
<style>
	#app {
		min-height: 100vh;
	}

	iframe {
		border: 0;
	}

	.ant-drawer-header-no-title [aria-label="Close"] {
		background: white;
	}

	.badge-offer {
		padding: .35rem .65rem;
		text-align: center;
		display: inline-block;
		border-radius: .2rem;
		background: #aaa;
	}

	.bg-green {
		background-color: var(--green);
		color: white;
	}

	.bg-pink {
		background-color: #FD6C83;
		color: white;
	}

	.bg-dark-green {
		background-color: #419E00;
		color: white;
	}

	.bg-teal {
		background-color: #134F5C;
		color: white;
	}

	/* table left-right scroll arrows css */
	.scroll-button {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		background-color: rgb(220 220 220 / 50%);
		border: none;
		color: #000;
		font-size: large;
		padding: 10px;
		cursor: pointer;
		height: 100%;
		display: flex;
		align-items: center;
	}

	.scroll-left {
		left: 0;
	}

	.scroll-right {
		right: 0;
	}

	.hide {
		display: none;
	}
</style>
